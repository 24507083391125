import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import { styled } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  organizationName: {
    color: 'rgb(0, 0, 0)',
  },
  notOrgName: {
    color: 'rgb(229, 104, 111)',
  },
  siteNameList: {
    '& span': {
      marginLeft: 5,
      borderRadius: 4,
      backgroundColor: '#dfe1e6',
      color: '#6b7283',
      padding: '2px 5px',
    },
  },
  siteOrgInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px 15px',
    flexWrap: 'wrap',
    fontSize: 14,
  },
  siteOrgDetail: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    lineHeight: '20px',
  },
  siteOrgName: {
    width: '115px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
}));
const InlineDialog = styled(TooltipPrimitive)({
  background: 'white',
  borderRadius: 4,
  boxShadow:
    'var(--ds-overlay, 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31))',
  boxSizing: 'content-box',
  color: '#091e42',
  maxHeight: 300,
  maxWidth: 340,
  padding: '8px 12px',
  fontSize: 14,
  zIndex: 99,
});
const OrgSiteInformation = ({ organizationName, siteNameList, className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const siteOrgNameRef = useRef(null);
  const [shouldDisplaySiteOrgNameTooltip, setShouldDisplaySiteOrgNameTooltip] =
    useState(false);

  useEffect(() => {
    if (siteOrgNameRef.current) {
      setShouldDisplaySiteOrgNameTooltip(
        siteOrgNameRef.current.scrollWidth > siteOrgNameRef.current.clientWidth,
      );
    }
  }, organizationName);
  return (
    <div
      className={
        className ? `${className} ${classes.siteOrgInfo}` : classes.siteOrgInfo
      }
    >
      <div className={`${classes.siteOrgDetail}`}>
        <span>{t('activity.organizationLabel')}:</span>
        {shouldDisplaySiteOrgNameTooltip ? (
          <Tooltip
            component={InlineDialog}
            content={organizationName || t('activity.notOrgName')}
            position='top'
          >
            <span
              ref={siteOrgNameRef}
              className={`${
                organizationName ? classes.organizationName : classes.notOrgName
              } ${classes.siteOrgName}`}
              style={{ cursor: 'pointer' }}
            >
              {organizationName || t('activity.notOrgName')}
            </span>
          </Tooltip>
        ) : (
          <span
            ref={siteOrgNameRef}
            className={`${
              organizationName ? classes.organizationName : classes.notOrgName
            } ${classes.siteOrgName}`}
          >
            {organizationName || t('activity.notOrgName')}
          </span>
        )}
      </div>
      <div className={`${classes.siteNameList}`}>
        {t('activity.siteLabel')}:
        {siteNameList.map((siteName) => (
          <span key={siteName}>{siteName}</span>
        ))}
      </div>
    </div>
  );
};
export default OrgSiteInformation;
