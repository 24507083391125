import { backEndApi } from '@root/libs';

const api = '/api/v1';
const activityPath = '/activity';
const activityAPIs = {
  getRegisteredUsers: (params) => {
    const { from, siteIds, productTypes } = params;
    let queryProductType = '';
    if (productTypes.length > 0) {
      productTypes.forEach((e) => {
        queryProductType += `&productTypes=${e}`;
      });
    }

    return backEndApi.get(
      `${api}${activityPath}/registered-users?from=${from}&siteIds=${siteIds}${queryProductType}`,
    );
  },
  getJiraActivity: (params) => {
    const { from, siteId } = params;
    return backEndApi.get(
      `${api}${activityPath}/jira?from=${from}&siteId=${siteId}`,
    );
  },

  getJiraContentActivity: (params) => {
    const { from, siteId } = params;
    return backEndApi.get(
      `${api}${activityPath}/jira/content?from=${from}&siteId=${siteId}`,
    );
  },

  getConfluenceActivity: (params) => {
    const { from, siteId } = params;
    return backEndApi.get(
      `${api}${activityPath}/confluence?from=${from}&siteId=${siteId}`,
    );
  },

  getAllUsersActivityStatistic: (params) => {
    const {
      from,
      productTypes,
      siteIds,
      type,
      activity,
      jiraSoftwareAccess,
      jiraServiceDeskAccess,
      jiraBusinessAccess,
      jiraProductDiscoveryAccess,
      confluenceAccess,
      jiraSoftwareActivity,
      jiraServiceDeskActivity,
      jiraBusinessActivity,
      confluenceActivity,
      jiraProductDiscoveryActivity,
      status: userStatus,
      productAccess,
      organization,
      site,
      name,
      email,
      addedDate,
      emailOutOfOrg,
      notOrgName,
      sort,
      size,
      page,
      currentUserId,
    } = params;
    let queryProductType = '';
    if (productTypes.length > 0) {
      productTypes.forEach((e) => {
        queryProductType += `&productTypes=${e}`;
      });
    }
    const body = {
      from,
      siteIds,
      queryProductType,
      type,
      activity,
      jiraSoftwareAccess,
      jiraServiceDeskAccess,
      jiraBusinessAccess,
      jiraProductDiscoveryAccess,
      confluenceAccess,
      jiraSoftwareActivity,
      jiraServiceDeskActivity,
      jiraBusinessActivity,
      confluenceActivity,
      jiraProductDiscoveryActivity,
      userStatus,
      productAccess,
      organization,
      site,
      name,
      email,
      addedDate,
      emailOutOfOrg,
      notOrgName,
      sort,
      size,
      page,
      currentUserId,
    };

    return backEndApi.post(`${api}${activityPath}/sites/users`, body);
  },
  getProductTypesActivity: (params) => {
    const { from, siteId } = params;
    return backEndApi.get(
      `${api}${activityPath}/product-types?from=${from}&siteId=${siteId}`,
    );
  },
  removeUserFromGroup: (params) => {
    const { siteId, groupId, accountId } = params;
    return backEndApi.delete(
      `${api}${activityPath}/${siteId}/group/user?groupId=${groupId}&accountId=${accountId}`,
    );
  },

  manageAccessUser: (body) =>
    backEndApi.post(`${api}${activityPath}/manage-access-user`, body),
};
export default activityAPIs;
