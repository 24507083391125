/* eslint-disable no-else-return */
import Button from '@atlaskit/button/standard-button';
import { Checkbox } from '@atlaskit/checkbox';
import { makeStyles } from '@material-ui/styles';
import { SiteMultipleSelectField } from '@root/components/common/select';
import { isSiteDisable } from '@root/utils';
import InviteIcon from '@atlaskit/icon/glyph/email';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  checkUnlicensedSite,
  getFirstLicenseSite,
} from '../../Activity/helper';
import { WarningMessage } from '../../common/message';

const useStyles = makeStyles(() => ({
  headerActivity: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 0',
    width: '100%',
  },
  message: {
    color: '#6D6E6F',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    margin: '5px 0px',
    fontSize: '14px',
  },
  errorMessage: {
    maxWidth: 750,
    width: 'calc(100vw - 30px)',
    padding: '15px',
  },
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0',
    minWidth: '70%',
    '& [role="radiogroup"]': {
      padding: '10px',
      marginLeft: '10px',
      display: 'flex',
      gap: '15px',
    },
  },
  searchSites: {
    minWidth: 350,
    fontSize: '14px',
    flexGrow: 1,
    '@media screen and (max-width: 1024px)': {
      flex: 1,
    },
  },
  searchTitle: {
    marginRight: '10px',
    minWidth: 'fit-content',
  },
  searchItem: {
    minWidth: 200,
    fontSize: '14px',
    '@media screen and (max-width: 1024px)': {
      flex: 1,
    },
  },
  checkBoxAll: {
    minWidth: 'fit-content',
    '& > label > span': {
      marginBottom: '3px',
      minWidth: 'fit-content',
    },
  },
  limitationSettingButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    '@media screen and (max-width: 1199px)': {
      flexWrap: 'wrap',
      gap: '8px 0',
    },
  },
  options: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    lineHeight: '26px',
  },
  optionDisable: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  topFilter: {
    width: '100%',
    marginBottom: '5px',
  },
  warningIcon: {
    marginRight: 8,
    cursor: 'pointer',
    minWidth: 25,
  },
  '@media screen and (max-width: 1200px)': {
    headerActivity: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    searchWrapper: {
      display: 'flex',
      maxWidth: '100%',
      margin: '0 0 1rem 0',
    },
  },
}));

const TopFilter = ({
  onChangeFilter = () => {},
  onClickInviteBtn = () => {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const siteSelectRef = useRef(null);
  const siteList = useSelector((appState) => appState.site.sites);

  const [siteListOption, setSiteListOption] = useState([]);
  const [siteSelectAll, setSiteSelectAll] = useState([]);
  const [siteSearching, setSiteSearching] = useState(null);
  const [isCheckAllSelect, setIsCheckAllSelect] = useState(false);
  const [isDisabledCheckbox, setIsDisabledCheckbox] = useState(true);
  const [isCheckSiteEmpty, setIsCheckSiteEmpty] = useState(false);
  const [isFirstSetSiteSearch, setIsFirstSetSiteSearch] = useState(true);
  const [isSiteMenuOpen, setIsSiteMenuOpen] = useState(false);

  const handleFirstLicenseSite = () => {
    const options = getFirstLicenseSite(siteList);
    if (options) {
      setSiteSearching(options);
    }
    return options;
  };
  const handleSelectSite = (e) => {
    if (e.length === 0) {
      handleFirstLicenseSite();
      setIsCheckSiteEmpty(true);
      setTimeout(() => {
        setIsCheckSiteEmpty(false);
      }, 6000);
    } else if (e.length === siteSelectAll.length) {
      setSiteSearching(e);
    } else {
      setSiteSearching(e);
    }
  };
  const handleSelectALL = () => {
    if (!isCheckAllSelect) {
      setIsCheckAllSelect(!isCheckAllSelect);
      setSiteSearching(siteSelectAll);
    } else {
      setIsCheckAllSelect(!isCheckAllSelect);
      handleFirstLicenseSite();
    }
  };

  const processSiteData = () => {
    const options = handleFirstLicenseSite();
    onChangeFilter({
      siteSearching: options,
    });
  };

  useEffect(() => {
    if (!siteSearching && siteList.length > 0 && isFirstSetSiteSearch) {
      setIsFirstSetSiteSearch(false);
      processSiteData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteList, isFirstSetSiteSearch]);

  useEffect(() => {
    const listSiteUnDisable = siteList.filter((i) => !i.isDisabled);
    if (listSiteUnDisable?.length > 0) {
      setIsDisabledCheckbox(false);
    }
  }, [siteList]);

  useEffect(() => {
    const optionList =
      siteList.map((i) => ({
        ...i,
        label: i.organizationName
          ? `${i.organizationName} ${i.name}`
          : `${t('activity.notOrgName')} ${i.name}`,
        value: i.siteId,
      })) || [];
    const siteSelectList = optionList.filter(
      (i) => !checkUnlicensedSite(i.value, siteList),
    );
    setSiteListOption(optionList);
    setSiteSelectAll(siteSelectList);
  }, [JSON.stringify(siteList)]);

  useEffect(() => {
    if (
      siteSearching?.length &&
      siteSearching?.length === siteSelectAll?.length
    ) {
      setIsCheckAllSelect(true);
    } else {
      setIsCheckAllSelect(false);
    }
    onChangeFilter({
      siteSearching,
    });
  }, [JSON.stringify(siteSelectAll), JSON.stringify(siteSearching)]);

  return (
    <div className={classes.topFilter}>
      <div className={classes.headerActivity}>
        <div className={classes.searchWrapper}>
          <span className={classes.searchTitle}>{t('activity.sites')}</span>
          <div className={classes.searchSites}>
            <div>
              <SiteMultipleSelectField
                ref={siteSelectRef}
                onFocus={() => setIsSiteMenuOpen(true)}
                onBlur={() => setIsSiteMenuOpen(false)}
                value={siteSearching}
                dataList={siteList}
                isOptionDisabled={(option) =>
                  checkUnlicensedSite(option.value, siteList)
                }
                onChange={handleSelectSite}
                onSelectALL={handleSelectALL}
                setIsCheckedAll={setIsCheckAllSelect}
                isCheckedAll={isCheckAllSelect}
                options={[...siteListOption]}
                siteLicenseList={[...siteSelectAll]}
                menuIsOpen={isSiteMenuOpen}
              />
            </div>
          </div>
        </div>
        <Button
          iconBefore={<InviteIcon />}
          appearance='primary'
          onClick={onClickInviteBtn}
          style={{ maxWidth: 'fit-content' }}
          isDisabled={siteList.every((i) => isSiteDisable(i))}
        >
          {t('userManagement.inviteButtonLabel')}
        </Button>
      </div>
      {isCheckSiteEmpty && (
        <WarningMessage
          text={t('activity.warningMessSelectSite')}
          color='#FFBF00'
        />
      )}
    </div>
  );
};

export default memo(TopFilter);
