import { ADMIN_DOMAIN, isSiteDisable } from '@root/utils';

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

const getModalStatus = (modals, key) => {
  const modal = modals.find((m) => m.key === key);
  const isOpen = modal?.state === true;
  return {
    isOpen,
    data: modal?.data,
  };
};
export const checkAllSitesDisable = (sites) => {
  let isAllDisabled = false;
  if (sites) {
    isAllDisabled = sites.every((site) => isSiteDisable(site));
  }
  return isAllDisabled;
};

export const isAdminEmail = (email) =>
  ADMIN_DOMAIN.some((domain) => email.includes(domain));

export { getWindowSize, getModalStatus };
